import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Row, Col, FormGroup, Button } from "reactstrap";
import { LoadingSpinner } from "../../../../../components/Common/LoadingSpinner/LoadingSpinner";
import { CouponRespository } from "../../../../../repositories/coupon";
import { useIsDesktop } from "../../../../../helpers/useIsDesktop";
import "react-credit-cards-2/dist/es/styles-compiled.css";
import { useHistory } from "react-router-dom";
import "./index.css";
import { toast } from "react-toastify";
import dropDown from "../../../..//../assets/images/drop-down.svg";
import { useParams } from "react-router-dom";
const full = 1;

export const NequiForm = ({discount, basePrice, handlerManagePayment }) => {
  const intl = new Intl.NumberFormat();
  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm();

  const [dataError, setDataError] = useState(false);
  const couponRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [validateCoupon, setValidateCoupon] = useState(false);
  const [couponLoading, setCouponLoading] = useState(false);
  const [couponMessage, setCouponMessage] = useState(null);
  const [couponType, setCouponType] = useState(null);
  const [open, setOpen] = useState(true);
  const history = useHistory();
  const { planId } = useParams();
  const showPrice = basePrice - basePrice * 0.5;

  const getPaymentMethodInfo = (data) => {
    return {
      type: "other",
      id_type: data.id_type,
      id: data.id,
      first_name: data.first_name,
      last_name: data.last_name,
      cellphone: data.cellphone,
      email: data.email,
      subscriptionId: planId,
    };
  };

  const onSave = async (data) => {
    setLoading(true);
    if (validateCoupon) {
      const repository = new CouponRespository();
      let response;
      if (validateCoupon.coupon_type === full) {
        response = await repository.aplicateCouponCol(couponRef.current.value, {
          type: "other",
          id_type: "",
          id: "",
          first_name: "",
          last_name: "",
          cellphone: "",
          email: "",
          subscriptionId: planId,
        });
      } else if (
        validateCoupon.coupon_type === 5 &&
        validateCoupon.coupon_type_name === "BEE50"
      ) {
        const paymentMethod = getPaymentMethodInfo(data);
        if (paymentMethod) {
          response = await repository.aplicateCouponCol(couponRef.current.value, paymentMethod );
          
        }
      } else if (validateCoupon.coupon_type === 5 &&
        validateCoupon.coupon_type_name === "BEEBASICO") {
            response = await repository.aplicateCouponCol(
              couponRef.current.value, {
                type: "card",
                id_type: "CC",
                id: "88888888",
                first_name: "John",
                last_name: "Doe",
                cellphone: "88888888",
                email: "test@correo.com",
                subscriptionId: Number(planId),
              }
            )
      } else {
        const paymentMethod = getPaymentMethodInfo(data);
        if (paymentMethod) {
          response = await repository.aplicateCouponCol(
            couponRef.current.value,
            paymentMethod
          );
        }
      }

      if (response) {
        if (response.status === 201) {
          history.push("/how-it-works");
        } else {
          toast.info(response.response.data.message);
        }
      }
    } else {
      const paymentMethod = getPaymentMethodInfo(data);
      await handlerManagePayment(paymentMethod);
    }
    setLoading(false);
  };

  const applyCupon = async () => {
    if (!couponRef.current.value) {
      return;
    }
    const repository = new CouponRespository();

    try {
      setCouponLoading(true);
      const response = await repository.validate(couponRef.current.value);
      if (response.data.coupon_type) {
        setCouponType(response.data.coupon_type);
      }
      if (response.data.coupon_type === 1) {
        setCouponMessage("*Membresía gratis de por vida");
        setDataError(false);
      } else if (response.data.coupon_type === 2) {
        setCouponMessage("*Mismo precio de por vida");
      } else if (response.data.coupon_type === 3) {
        setCouponMessage("*Mismo precio de por vida");
      } else if (response.data.coupon_type === 4) {
        setCouponMessage("*Mismo precio de por vida");
      } else if (response.data.coupon_type === 5) {
        if (response.data.coupon_type_name === "BEE50") {
          setCouponMessage("50% De Descuento");
        }
        if (response.data.coupon_type_name === "BEEBASICO") {
          setCouponMessage("Plan Básico Gratis De Por Vida");
        }
      } else if (response.response.status in [404, 400]) {
        setCouponMessage(response.response.data.message);
        setValidateCoupon(false);
      }
      setValidateCoupon(response?.data);
    } catch (e) {
      setCouponMessage("El cupón ingresado no es válido.");
      setValidateCoupon(false);
      console.error(e);
    } finally {
      setCouponLoading(false);
    }
  };

  const isDesktop = useIsDesktop();

  return (
    <form onSubmit={handleSubmit(onSave)}>
      <Row>
        <div className="pay-nequi-form">
          <Col className="form-content">
            <Col>
              <FormGroup className="input-group">
                <div className="position-relative w-100">
                  <label
                    className={`poppins-family ${isDesktop ? "fw-bold" : ""}`}
                  >
                    Nombre
                  </label>
                  <input
                    {...register("first_name", {
                      required: validateCoupon === false &&  "Este campo es requerido",
                      minLength: {
                        value: 2,
                        message: "El minimo de caracteres es 2",
                      },
                    })}
                    className="form-control w-100 payment-method-input ps-5"
                    style={{ color: "#ECA109", fontWeight: "bold" }}
                  />
                </div>
                <small className="poppins-family ps-3">
                  {errors.first_name?.message}
                </small>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup className="input-group">
                <div className="position-relative w-100">
                  <label
                    className={`poppins-family ${isDesktop ? "fw-bold" : ""}`}
                  >
                    Apellidos
                  </label>
                  <input
                    {...register("last_name", {
                      required: validateCoupon === false &&  "Este campo es requerido",
                      minLength: {
                        value: 2,
                        message: "El minimo de caracteres es 2",
                      },
                    })}
                    className="form-control w-100 payment-method-input ps-5"
                    style={{ color: "#ECA109", fontWeight: "bold" }}
                  />
                </div>
                <small className="poppins-family ps-3">
                  {errors.last_name?.message}
                </small>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup className="input-group">
                <div className="position-relative w-100">
                  <label
                    className={`poppins-family ${isDesktop ? "fw-bold" : ""}`}
                  >
                    Tipo de Identificación
                  </label>
                  <select
                    {...register("id_type", { required: validateCoupon === false &&  "Este campo es requerido" })}
                    className="form-select w-100 payment-method-input ps-5"
                    style={{ color: "#ECA109", fontWeight: "bold" }}
                  >
                    <option value="CC">Cédula de Cuidadania</option>
                    <option value="CEDULA DE IDENTIDAD">
                      Cédula de Identidad
                    </option>
                    <option value="CE">Cedula Extrangera</option>
                    <option value="PP">Pasaporte</option>
                    <option value="NIT">
                      Numero de Identificación Tributaria
                    </option>
                  </select>
                </div>
                {/* <small className="poppins-family ps-3">
                                {erroredInputs.cardNumber && erroredInputs.cardNumber}
                            </small> */}
              </FormGroup>
            </Col>
            <Col>
              <FormGroup className="input-group">
                <div className="position-relative w-100">
                  <label
                    className={`poppins-family  identication-label ${
                      isDesktop ? "fw-bold" : ""
                    }`}
                  >
                    Número de identificación
                  </label>
                  <input
                    {...register("id", {
                      required: validateCoupon === false &&  "Este campo es requerido",
                      minLength: {
                        value: 4,
                        message: "Debe ser mayor a 4 dígitos",
                      },
                      pattern: {
                        value: /^[0-9]+$/,
                        message: "Solo se permiten números",
                      },
                    })}
                    className="form-control w-100 payment-method-input ps-5"
                    style={{ color: "#ECA109", fontWeight: "bold" }}
                  />
                </div>
                <small className="poppins-family ps-3">
                  {errors.id?.message}
                </small>
              </FormGroup>
            </Col>
            <Col className="phone-field">
              <FormGroup className="input-group">
                <div className="position-relative w-100">
                  <label
                    className={`poppins-family ${isDesktop ? "fw-bold" : ""}`}
                  >
                    Teléfono
                  </label>
                  <input
                    {...register("cellphone", {
                      required: validateCoupon === false &&  "Este campo es requerido",
                      minLength: {
                        value: 4,
                        message: "Debe ser mayor a 4 dígitos",
                      },
                      pattern: {
                        value: /^[0-9]+$/,
                        message: "Solo se permiten números",
                      },
                    })}
                    className="form-control w-100 payment-method-input ps-5"
                    style={{ color: "#ECA109", fontWeight: "bold" }}
                  />
                </div>
                <small className="poppins-family ps-3">
                  {errors.cellphone?.message}
                </small>
              </FormGroup>
            </Col>
            <Col className="email-field">
              <FormGroup className="input-group">
                <div className="position-relative w-100">
                  <label
                    className={`poppins-family ${isDesktop ? "fw-bold" : ""}`}
                  >
                    Correo Electrónico
                  </label>
                  <input
                    {...register("email", {
                      required: validateCoupon === false &&  "Este campo es requerido",
                      pattern: {
                        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                        message: "Formato de correo electrónico no válido",
                      },
                    })}
                    className="form-control w-100 payment-method-input ps-5"
                    style={{ color: "#ECA109", fontWeight: "bold" }}
                  />
                </div>
                <small className="poppins-family ps-3">
                  {errors.email?.message}
                </small>
              </FormGroup>
            </Col>
          </Col>
          <hr />
          <Row
            className={`bg-white p-4 ${isDesktop ? "mt-4" : ""}`}
            style={isDesktop ? { borderRadius: "2.5rem" } : {}}
          >
            <Col lg="12" xs={12}>
              <div className="input-group flex-column">
                <Row>
                  <Col className="coupon-header">
                    <label className="poppins-family fw-bold">
                      Tengo un código de promoción
                    </label>

                    <button
                      type="button"
                      onClick={() => setOpen(!open)}
                      className="dropdown-btn"
                    >
                      {open ? (
                        <img src={dropDown} alt="dropdown icon" />
                      ) : (
                        <img
                          src={dropDown}
                          alt="dropdown icon"
                          className={`${!open && "dropdown-close"}`}
                        />
                      )}
                    </button>
                  </Col>
                </Row>
                <div
                  className={`coupon-controls-control ${
                    isDesktop ? "" : "d-flex align-items-center"
                  } ${open ? "show-coupon" : "hide-coupon"}`}
                >
                  <input
                    className={`coupon-input form-control payment-method-input fw-bold ${
                      isDesktop ? "ps-5" : "ps-3"
                    }`}
                    placeholder="Escribe tu código aquí"
                    ref={couponRef}
                    style={{ borderRadius: "0.25rem !important" }}
                  />

                  <Button
                    className="cupon-code-btn"
                    size="lg"
                    onClick={applyCupon}
                    disabled={false}
                  >
                    {couponLoading ? <LoadingSpinner /> : "Aplicar"}
                  </Button>
                </div>
                <Row>
                  <Col sm={12} className="mt-2">
                    {!!couponMessage && (
                      <span
                        className="span-free-membership fw-bold"
                        style={validateCoupon ? {} : { color: "#FF8264" }}
                      >
                        {couponMessage}
                      </span>
                    )}
                  </Col>
                </Row>
              </div>
            </Col>
            <Row>
              <Col xs={8} lg={5} className="mt-3">
                <div className="d-flex flex-column">
                  <label className="label-price-details fw-bold">
                    Subtotal
                  </label>
                  <label className="label-price-details fw-bold mt-3">
                    Total
                  </label>
                </div>
              </Col>
              <Col xs={4} lg={7} className="mt-3">
                <div className="d-flex flex-column">
                  <label className="label-price-details fw-bold">
                    {`$${
                      couponType
                        ? couponType === 1
                          ? "0.00"
                          : couponType === 2
                          ? "9.99"
                          : couponType === 3
                          ? "54.00"
                          : couponType === 5 &&
                            validateCoupon.coupon_type_name === "BEEBASICO"
                          ? "00.00"
                          : couponType === 5
                          && validateCoupon.coupon_type_name === "BEE50"
                          ? intl.format(showPrice)
                          : intl.format(basePrice)
                        : discount
                        ? intl.format(discount) 
                        :intl.format(discount)
                    }`}
                  </label>
                  <label className="label-price-details fw-bold mt-3">
                    {`$${
                      couponType
                        ? couponType === 1
                          ? "0.00"
                          : couponType === 2
                          ? "9.99"
                          : couponType === 3
                          ? "54.00"
                          : couponType === 5 &&
                            validateCoupon.coupon_type_name === "BEEBASICO"
                          ? "00.00"
                          : couponType === 5
                          && validateCoupon.coupon_type_name === "BEE50"
                          ? intl.format(showPrice)
                          : intl.format(basePrice)
                        : discount
                        ? intl.format(discount) 
                        :intl.format(basePrice)
                    }`}
                  </label>
                </div>
              </Col>
            </Row>
            <Col sm="12" className="mt-4">
              <Row>
                <Col sm={12} md={12} lg={12}>
                  <Button type="submit" className="payment-form-btn" size="lg">
                    {loading && <LoadingSpinner />}
                    EMPEZAR YA <i className="fas fa-arrow-right" />
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={12} lg={12}>
                  <small
                    className="text-danger mt-1"
                    style={{ fontSize: "16px" }}
                  >
                    {dataError && "*Verifique los datos del formulario"}
                  </small>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Row>
    </form>
  );
};
